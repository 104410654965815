import { Box, Typography } from "@mui/material";
import React from "react";
import IMAGES from "../../../../../assets/images";

import { ThemeContext } from "@mui/styled-engine";

interface Props {
    imgPath: any;
    title: string;
    prize: string[];
    description: string;
}

function PrizeItem(props: Props) {
    const theme: any = React.useContext(ThemeContext);

    return (
        <div style={styles.prizeItem}>
            {/* Render image if imgPath is not "" */}
            {props.imgPath && (
                <Box
                    component="img"
                    className="prize-img"
                    src={IMAGES[props.imgPath]}
                    alt={props.title}
                    style={styles.prizeImg}
                />
            )}
            <Typography
                variant="h3"
                color="secondary"
                style={{ marginBottom: "0.8rem" }}
            >
                {props.title}
            </Typography>
            {props.prize.length !== 1 ? (
                props.prize.map((prize) => (
                    <li
                        style={{
                            ...theme.typography.body1,
                            listStyle: "decimal",
                        }}
                    >
                        {prize}
                    </li>
                ))
            ) : (
                <div style={theme.typography.body1}>{props.prize[0]}</div>
            )}
            {props.description.length > 0 && (
                <Typography variant="subtitle1" style={styles.prizeDescription}>
                    <i>{props.description}</i>
                </Typography>
            )}
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    prizeImg: {
        width: 80,
        height: 80,
        margin: 20,
    },
    prizeItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        margin: 10,
        padding: 10,
    },
    prizeDescription: {
        textAlign: "center",
        width: "250px",
        marginTop: "1rem",
    },
};

export default React.memo(PrizeItem);
