import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress, Unstable_Grid2 as Grid, Snackbar, Alert } from "@mui/material";
import UserInformation from "./components/UserInformation";
import TeamInformation from "./components/TeamInformation";
import EventPasswordPanel from "./components/EventPasswordPanel";
import { useLogin } from "../../hooks/LoginProvider";
import { Team, User } from "../../types/types";
import { getUser } from "../../apis/user";
import { getTeam } from "../../apis/team";
import "./styles.css";
import BottomImage from "./components/BottomImage";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const { accessToken, setLogin } = useLogin();
    const [team, setTeam] = useState<Team>();
    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "success">("error");
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const nav = useNavigate();

    const init = useCallback(async () => {
        setIsLoading(true);

        try {
            const user = await getUser(accessToken, setLogin);
            setUser(user);
        } catch (err) {
            console.log(err);
            nav("/login");
        }

        // loading will be set to false by fetchTeam
    }, [accessToken, setLogin, nav]);

    const fetchTeam = useCallback(async () => {
        if (!user) {
            setTeam(undefined);
            return;
        }

        setIsLoading(true);
        if (user.teamId) {
            try {
                const team = await getTeam({ teamId: user.teamId }, accessToken, setLogin);
                setTeam(team);
            } catch (err) {
                console.log(err);
            }
        } else {
            setTeam(undefined);
        }
        setIsLoading(false);
    }, [accessToken, setLogin, user]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        fetchTeam();
    }, [user]);

    const openSnackbar = (severity: "error" | "success", message: string) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setIsSnackbarOpen(true);
    };


    return (
        <div className="dashboard">
            {isLoading ? (
                <CircularProgress sx={{ marginBottom: "calc(100vh - 400px)" }} />
            ) : (
                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        {user && <UserInformation user={user} refreshPage={init} setIsLoading={setIsLoading} openSnackbar={openSnackbar} />}
                        {/** temporarily disabled for gamecraft 2024
                         * {user && <EventPasswordPanel eventList={user.eventsParticipated} refreshPage={init} openSnackbar={openSnackbar} />} 
                         */}
                    </Grid>
                    <Grid xs={12} md={8}>
                        <TeamInformation team={team} refreshPage={init} setIsLoading={setIsLoading} openSnackbar={openSnackbar} />
                    </Grid>
                </Grid>
            )}

            <BottomImage />

            <Snackbar
                open={isSnackbarOpen}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={4000}
                onClose={() => setIsSnackbarOpen(false)}
            >
                <Alert severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default React.memo(Dashboard);
