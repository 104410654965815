const IMAGES: Record<string, any> = {
    // Logos
    "gamecraft": require("./images/gamecraftLogo.png"),
    "gamecraftIcon": require("./images/gamecraftIcon.png"),
    "gamecraftIconWhite": require("./images/gamecraftIconWhite.png"),

    // Decorative
    "alien1": require("./images/alien1.png"),
    "alien2": require("./images/alien2.png"),
    "alien3": require("./images/alien3.png"),

    "astronaut": require("./images/astronaut.png"),

    "rocket": require("./images/rocket.png"),

    "planet1": require("./images/planet_1.PNG"),
    "planet2": require("./images/planet_2.PNG"),
    "planet3": require("./images/planet_3.PNG"),
    "planet4": require("./images/planet_4.PNG"),
    "planet5": require("./images/planet_5.PNG"),
    "planet6": require("./images/planet_6.PNG"),
    "planet7": require("./images/planet_7.PNG"),

    "moon": require("./images/moon.png"),


    "spaceWave": require("./images/spaceWave.png"),

    "trophy": require("./images/trophy.png"),
    "medal": require("./images/medal.png"),

    "moon2": require("./images/moon2.png"),

    "ufo": require("./images/ufo.png"),
    "ufo2": require("./images/ufo2.png"),

    "ufo1body": require("./images/ufo1body.png"),
    "ufo1glow": require("./images/ufo1glow.png"),


    "room": require("./images/room.png"),

    // Background imgs
    "stars": require("./images/stars.png"),

    // Past Events
    "pastEventPic1": require("./images/pastEvents/pic1.jpg"),
    "pastEventPic2": require("./images/pastEvents/pic2.jpg"),
    "pastEventPic3": require("./images/pastEvents/pic3.jpg"),
    "pastEventPic4": require("./images/pastEvents/pic4.jpg"),
    "pastEventPic5": require("./images/pastEvents/pic5.jpg"),
    "pastEventPic6": require("./images/pastEvents/pic6.jpg"),
    "pastEventPic7": require("./images/pastEvents/pic7.jpg"),
    "pastEventPic8": require("./images/pastEvents/pic8.jpg"),
    "pastEventPic9": require("./images/pastEvents/pic9.jpg"),


    // Past Jams
    "sliggy_slime": require("./images/pastjams/sliggy_slime.png"),
    "cogsworks": require("./images/pastjams/cogworks_cover.png"),
    "kitten_mitten": require("./images/pastjams/kitten_mitten_cover.png"),
    "memory_lanes": require("./images/pastjams/memory_lanes_cover.png"),
    "A2wJky66rH": require("./images/pastjams/A2wJky66rH_cover.png"),
    "runic_conjecture": require("./images/pastjams/runic-conjecture.png"),

    "profile_card_bg": require("./images/profile_card_bg.png"),

    //sponsor banners
    "koei_banner": require("./images/sponsors/koei_Banner.png"),
    "century_games_banner": require("./images/sponsors/century_games_banner.jpg"),
    "nus_computing_banner": require("./images/sponsors/nus_computing_banner.png"),

    //sponsor logos
    "nusLogo": require("./images/sponsors/nusLogo.png"),
    "gentlebrosLogo": require("./images/sponsors/gentlebrosLogo.png"),
    "koeiTecmoLogo": require("./images/sponsors/KoeiTecmoLogo.png"),
    "riotGamesLogo": require("./images/sponsors/riotGamesLogo.png"),
    "centuryGamesLogo": require("./images/sponsors/centuryGamesLogo.png"),
    "battlebrewLogo": require("./images/sponsors/BattlebrewProductionLogo.webp"),
    "magicblocksLogo": require("./images/sponsors/magicBlocksLogo.png"),

    //judges/speakers
    "nusgdg": require("./images/guestsPictures/NUSGDG_LOGO.png"),
    "kokTungCenturyGames": require("./images/guestsPictures/century_games_kok_tung.jpg"),
    "cashOngCenturyGames": require("./images/guestsPictures/century_games_cash_ong.jpg"),
    "koeiEileen": require("./images/guestsPictures/koei_eileen.jpg"),
    "kahoKoei": require("./images/guestsPictures/koei_ka_ho.jpg"),
    "jaredGumi": require("./images/guestsPictures/gumi_jared.jpg"),
    "yuanleiCenturyGames": require("./images/guestsPictures/century_games_yuanlei.jpg"),
    "kangsoonNyp": require("./images/guestsPictures/nyp_kangsoon.jpg"),
    "giulio": require("./images/guestsPictures/giulio.jpg"),
    "alexNus": require("./images/guestsPictures/nus_alex.jpg"),
}

export default IMAGES;