import { Box, Typography } from "@mui/material";
import React from "react";
import { EventDetails } from "../../../../../types/types";

interface Props {
    eventDetail?: EventDetails;
}

function EventScheduleDetail({ eventDetail }: Props) {
    const FONT_SIZE = { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem" };

    const renderEndTime = (endTime: string | undefined) => {
        if (!endTime) {
            return (
                <Typography sx={{ fontSize: FONT_SIZE }}>
                    {/* Put &nbsp so the number of characters will match with undefined endTime render */}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
            );
        }

        return (
            <Typography sx={{ fontSize: FONT_SIZE }}>
                -{eventDetail?.endTime}
            </Typography>
        );
    };

    return (
        <Box sx={[styles.mainContainer, { width: "100%" }]}>
            {/* Seperated start and end time in diff typo component to prevent them from being wrapped if the event title is long due to flex */}
            <div style={styles.mainContainer}>
                {eventDetail?.startTime ? (
                    <Typography sx={{ fontSize: FONT_SIZE }}>
                        {eventDetail?.startTime}
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: FONT_SIZE }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                )}
                {renderEndTime(eventDetail?.endTime)}
            </div>
            <Typography
                sx={{ fontSize: FONT_SIZE, marginLeft: "1rem" }}
                align="left"
            >
                {eventDetail?.eventName}
            </Typography>
        </Box>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    mainContainer: {
        display: "flex",
        flexDirection: "row",
    },
};

export default React.memo(EventScheduleDetail);
